import { routers, routing } from 'outlinejs';

import AuthRouter from './auth/urls';
import CartRouter from './cart/urls';
import CheckoutRouter from './checkout/urls';
import OrderRouter from './orders/urls';
import ThankYouRouter from './thankyou/urls';
import PaymentRouter from './payment/urls';
import ResetRouter from './reset/urls';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.include(CartRouter),
      payment: routing.include(PaymentRouter),
      checkout: routing.include(CheckoutRouter),
      'order-confirmation': routing.include(OrderRouter),
      thankyou: routing.include(ThankYouRouter),
      auth: routing.include(AuthRouter),
      reset: routing.include(ResetRouter)
    };
  }
}
