import { conf } from 'outlinejs';
import { BaseModel, mergeBackboneOptions } from '../core/models';
import Logger from '../core/logger';
import {
  AlbumColorCorrectionTypesCollection,
  EventBookColorCorrectionTypesCollection,
  HomeDecorColorCorrectionTypesCollection,
  MattedPrintsColorCorrectionTypesCollection
} from '../color-corrections/managers';

import LOADING_IMAGE_URL from '../core/media/images/loading-image.gif';
import ERROR_IMAGE_URL from '../core/media/images/error-image.png';
import NO_IMAGE_URL from '../core/media/images/no-image-found.png';

export class ProfessionalPreOrder extends BaseModel {
  constructor(...args) {
    super(...args);
    this._descriptionVisible = false;
    this._descriptionLoaded = undefined;
    this._isDescriptionLoading = undefined;
  }

  get urlRoot() {
    return conf.settings.PROFESSIONAL_PRE_ORDERS_URL;
  }

  get id() {
    return this.get('id');
  }

  get configurationUrl() {
    return this.get('configurationUrl');
  }

  set configurationUrl(value) {
    this.set('configurationUrl', value);
  }

  get configurationId() {
    return this.get('configurationId');
  }

  set configurationId(value) {
    this.set('configurationId', value);
  }

  get productType() {
    return this.get('productType');
  }

  set productType(value) {
    this.set('productType', value);
  }

  get productName() {
    return this.get('productName');
  }

  set productName(value) {
    this.set('productName', value);
  }

  get unitaryFormattedPrice() {
    return this.get('unitaryFormattedPrice');
  }

  set unitaryFormattedPrice(value) {
    this.set('unitaryFormattedPrice', value);
  }

  get unitaryFullFormattedPrice() {
    return this.get('unitaryFullFormattedPrice');
  }

  set unitaryFullFormattedPrice(value) {
    this.set('unitaryFullFormattedPrice', value);
  }

  get totalFormattedPrice() {
    return this.get('totalFormattedPrice');
  }

  set totalFormattedPrice(value) {
    this.set('totalFormattedPrice', value);
  }

  get totalFullFormattedPrice() {
    return this.get('totalFullFormattedPrice');
  }

  set totalFullFormattedPrice(value) {
    this.set('totalFullFormattedPrice', value);
  }

  get totalPrice() {
    return this.get('totalPrice');
  }

  set totalPrice(value) {
    this.set('totalPrice', value);
  }

  get totalFullPrice() {
    return this.get('totalFullPrice');
  }

  set totalFullPrice(value) {
    this.set('totalFullPrice', value);
  }

  get name() {
    return this.get('name');
  }

  set name(value) {
    this.set('name', value);
  }

  get description() {
    return this.get('description');
  }

  set description(value) {
    this.set('description', value);
  }

  get productDescription() {
    return this.get('productDescription');
  }

  set productDescription(value) {
    this.set('productDescription', value);
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get projectId() {
    return this.get('projectId');
  }

  set projectId(value) {
    this.set('projectId', value);
  }

  get serviceConfigurationTypeCode() {
    return this.get('serviceConfigurationTypeCode');
  }

  set serviceConfigurationTypeCode(value) {
    this.set('serviceConfigurationTypeCode', value);
  }

  get serviceConfigurationTypeName() {
    return this.get('serviceConfigurationTypeName');
  }

  set serviceConfigurationTypeName(value) {
    this.set('serviceConfigurationTypeName', value);
  }

  get quantity() {
    return this.get('quantity');
  }

  set quantity(value) {
    this.set('quantity', value);
  }

  get layoutStatus() {
    return this.get('layoutStatus');
  }

  set layoutStatus(value) {
    this.set('layoutStatus', value);
  }

  get filesStatus() {
    return this.get('filesStatus');
  }

  set filesStatus(value) {
    this.set('filesStatus', value);
  }

  get editConfigurationUrl() {
    return this.get('editConfigurationUrl');
  }

  set editConfigurationUrl(value) {
    this.set('editConfigurationUrl', value);
  }

  get serviceType() {
    return this.get('serviceType');
  }

  set serviceType(value) {
    this.set('serviceType', value);
  }

  get setServiceUrl() {
    return this.get('setServiceUrl');
  }

  set setServiceUrl(value) {
    this.set('setServiceUrl', value);
  }

  get editLayoutUrl() {
    return this.get('editLayoutUrl');
  }

  set editLayoutUrl(value) {
    this.set('editLayoutUrl', value);
  }

  get configurationGuid() {
    return this.get('configurationGuid');
  }

  set configurationGuid(value) {
    this.set('configurationGuid', value);
  }

  get previewUrl() {
    return this.get('previewUrl');
  }

  set previewUrl(value) {
    this.set('previewUrl', value);
  }

  get previewImageFileId() {
    return this.get('previewImageFileId');
  }

  set previewImageFileId(value) {
    this.set('previewImageFileId', value);
  }

  get previewImage() {
    return this.get('previewImage');
  }

  set previewImage(value) {
    this.set('previewImage', value);
  }

  get previewStatus() {
    return this.get('previewStatus');
  }

  set previewStatus(value) {
    this.set('previewStatus', value);
  }

  get configurationStatus() {
    return this.get('configurationStatus');
  }

  set configurationStatus(value) {
    this.set('configurationStatus', value);
  }

  get productConfigurationValid() {
    return this.get('productConfigurationValid');
  }

  set productConfigurationValid(value) {
    this.set('productConfigurationValid', value);
  }

  get colorCorrectionCode() {
    return this.get('colorCorrectionCode');
  }

  set colorCorrectionCode(value) {
    this.set('colorCorrectionCode', value);
  }

  get colorCorrectionFormattedPrice() {
    return this.get('colorCorrectionFormattedPrice');
  }

  set colorCorrectionFormattedPrice(value) {
    this.set('colorCorrectionFormattedPrice', value);
  }

  get colorCorrectionFormattedDiscountedPrice() {
    return this.get('colorCorrectionFormattedDiscountedPrice');
  }

  set colorCorrectionFormattedDiscountedPrice(value) {
    this.set('colorCorrectionFormattedDiscountedPrice', value);
  }

  get requestFullServiceDesignDate() {
    return this.get('requestFullServiceDesignDate');
  }

  set requestFullServiceDesignDate(value) {
    this.set('requestFullServiceDesignDate', value);
  }

  get isOutOfStock() {
    return this.get('isOutOfStock');
  }

  set isOutOfStock(value) {
    this.set('isOutOfStock', value);
  }

  get previewSvgContent() {
    return this.get('previewSvgContent');
  }

  set previewSvgContent(value) {
    this.set('previewSvgContent', value);
  }

  get designServiceCode() {
    return this.get('designServiceCode');
  }

  set designServiceCode(value) {
    this.set('designServiceCode', value);
  }

  get designServiceFormattedPrice() {
    return this.get('designServiceFormattedPrice');
  }

  set designServiceFormattedPrice(value) {
    this.set('designServiceFormattedPrice', value);
  }

  get designServiceFormattedDiscountedPrice() {
    return this.get('designServiceFormattedDiscountedPrice');
  }

  set designServiceFormattedDiscountedPrice(value) {
    this.set('designServiceFormattedDiscountedPrice', value);
  }

  // methods
  fullImageUrl() {
    if (this.previewStatus === 'LOADING') {
      return LOADING_IMAGE_URL;
    }
    if (this.previewStatus === 'LOADED') {
      if (this.previewImage && this.previewImage.fileUrl) {
        return this.previewImage.fileUrl;
      }
      Logger.error('Full image url not found.');
      return ERROR_IMAGE_URL;
    }
    return ERROR_IMAGE_URL;
  }

  previewImageUrl(thumbnailId = 5) {
    if (this.previewStatus === 'LOADING') {
      return LOADING_IMAGE_URL;
    }
    if (this.previewStatus === 'LOADED') {
      let thumb = null;
      if (!this.previewImage) {
        return NO_IMAGE_URL;
      }

      if (this.previewImage && this.previewImage.thumbnails.length > 0) {
        const thumbnailsLength = this.previewImage.thumbnails.length;
        for (let i = 0; i < thumbnailsLength; i++) {
          thumb = this.previewImage.thumbnails[i];
          if (this.previewImage.thumbnails[i].thumbId === thumbnailId) {
            break;
          }
        }
      } else {
        thumb = { fileUrl: this.previewImage.fileUrl };
      }
      if (thumb) {
        return thumb.fileUrl;
      }
    } else {
      return ERROR_IMAGE_URL;
    }
  }

  async getColorCorrectionItems() {
    if (this.serviceType) {
      const params = {
        serviceType: this.serviceType,
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
      };
      if (this.productType === conf.settings.PRODUCT_TYPE_HOME_DECOR) {
        const colorCorrectionItems = await new HomeDecorColorCorrectionTypesCollection().fetch({
          data: params,
          headers: {
            'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE
          }
        });
        return colorCorrectionItems.toJSON();
      }
    }
    if (this.serviceConfigurationTypeCode) {
      const params = {
        serviceConfigurationTypeCode: this.serviceConfigurationTypeCode
      };

      if (this.productType === conf.settings.PRODUCT_TYPE_ALBUM) {
        const colorCorrectionItems = await new AlbumColorCorrectionTypesCollection().fetch({
          data: params
        });
        return colorCorrectionItems.toJSON();
      }

      if (this.productType === conf.settings.PRODUCT_TYPE_EVENT_BOOK) {
        const colorCorrectionItems = await new EventBookColorCorrectionTypesCollection().fetch({
          data: params
        });
        return colorCorrectionItems.toJSON();
      }

      if (this.productType === conf.settings.PRODUCT_TYPE_MATTED_PRINTS) {
        const colorCorrectionItems = await new MattedPrintsColorCorrectionTypesCollection().fetch({
          data: params
        });
        return colorCorrectionItems.toJSON();
      }
    }
  }

  fetch(options = {}) {
    const params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }

  async fetchDescription(options = {}) {
    const previousModel = this.toJSON();

    const params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    };
    options = mergeBackboneOptions(options, params);
    this.url = `${this.urlRoot}${this.id}/product-description`;

    this.isDescriptionLoading = true;

    await super.fetch(options);

    this.url = `${this.urlRoot}${this.id}`;
    this.isDescriptionLoading = false;
    const descriptionModel = this.toJSON();

    this.set(previousModel);
    for (const [key, value] of Object.entries(this.attributes)) {
      if (value === null) {
        this[key] = descriptionModel[key];
      }
    }

    this._descriptionLoaded = true;
    return this;
  }

  get isDescriptionLoading() {
    return this._isDescriptionLoading;
  }

  set isDescriptionLoading(value) {
    this._isDescriptionLoading = value;
    // return value;
  }

  get descriptionLoaded() {
    return this._descriptionLoaded;
  }

  get descriptionVisible() {
    return this._descriptionVisible;
  }

  set descriptionVisible(value) {
    this._descriptionVisible = value;
    // return value;
  }
}
