import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import axios from 'axios';

import OAuth2 from '../auth/oauth';
import { EpocaBaseController } from '../core/controllers';

import { ResetView } from './views';

/**
 * This application is only used to delete projects of datadog users.
 * The goal is to have all tests without projects in cart or bucket to ensure the same behaviour
 *
 */
export class ResetController extends EpocaBaseController {
  get view() {
    return ResetView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering,
      success: this.success,
      error: this.error
    });
  }

  async initContentProps() {
    await super.initContentProps();

    this.success = undefined;
    this.error = undefined;
  }

  async init() {
    await this.initContentProps();

    this.startInitialRendering();

    if (runtime.isClient) {
      if (!conf.settings.RESET_PROJECTS_ENABLED_SHOP_CODES.includes(this.customerUser.shopCode)) {
        this.response.navigate('unauthorized');
        return;
      }
      this.stopInitialRendering();
    }
  }

  async backToSite() {
    this.response.navigate('cart:main');
  }

  async deleteAll() {
    this.startInitialRendering();

    this.error = undefined;
    this.success = undefined;

    const httpRequestConfig = {
      headers: {
        Authorization: OAuth2.getBearerToken(),
        'x-api-key': conf.settings.X_API_KEY,
        'Accept-Language': this.request.language
      }
    };

    try {
      await axios.post(
        conf.settings.PROFESSIONAL_PROJECTS_RESET_URL,
        { shopCode: this.customerUser.shopCode },
        httpRequestConfig
      );
      this.stopInitialRendering();

      this.success = true;
      this.render(this.context);
    } catch (error) {
      this.error = error.response.data;
      this.stopInitialRendering();
    }
  }
}
