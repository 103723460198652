import { conf } from 'outlinejs';
import { BaseCollection } from '../core/managers';

import { ProfessionalPreOrder } from './models';

export class ProfessionalPreOrdersCollection extends BaseCollection {
  get name() {
    return 'ProfessionalPreorders';
  }

  get url() {
    return conf.settings.PROFESSIONAL_PRE_ORDERS_URL;
  }

  get model() {
    return ProfessionalPreOrder;
  }

  filterByOrderGuidAndShopCode(orderGuid = null, shopCode = null) {
    if (orderGuid && shopCode) {
      const params = {
        orderGuid,
        shopCode,
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
      };
      return this.fetch({ data: params });
    }
    return [];
  }

  filterByShopCode(shopCode = null) {
    if (shopCode) {
      const params = {
        shopCode,
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
      };
      return this.fetch({ data: params });
    }
    return [];
  }

  async filterByProjectId(projectId = null) {
    if (projectId) {
      const params = {
        projectId,
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
      };
      return await this.fetch({ data: params });
    }
    return [];
  }

  async fetchPreOrders(preOrdersDataList, includePreOrderDescription = false) {
    if (this.models.length === 0) {
      const promises = [];
      preOrdersDataList.forEach((preOrdersData) => {
        promises.push(
          new ProfessionalPreOrder({ id: preOrdersData.preorderId }).fetch(
            //eslint-disable-line
            {
              data: {
                includeProductDescription: includePreOrderDescription
              }
            }
          )
        );
      });

      this.models = await Promise.all(promises);
      return this.models;
    }

    return await Promise.all(
      this.models.map((preOrder) =>
        preOrder.fetch({
          data: {
            includeProductDescription: preOrder.descriptionVisible
          }
        })
      )
    );
  }
}
