import 'babel-polyfill';
import { Boot, conf } from 'outlinejs';
import Translation from '@outlinejs/translation';
import RootRouter from './urls';
import Settings from './settings';
import TagManager from 'react-gtm-module';

import Logger from './core/logger';

import it from './i18n/it.json';
import es from './i18n/es.json';
import en from './i18n/en.json';

// ADD Translations
Translation.addTranslation(it);
Translation.addTranslation(es);
Translation.addTranslation(en);

Boot.init(Settings, RootRouter, 'main');
const gtmId = conf.settings.GTM_ID;

TagManager.initialize({
  gtmId
});
Logger.initialize();
