import { conf } from 'outlinejs';

import Cookies from 'js-cookie';

export default class OAuth2 {
  constructor(
    redirectUri,
    clientId = conf.settings.OAUTH2_CLIENT_ID,
    clientSecret = conf.settings.OAUTH2_CLIENT_SECRET,
    tokenUrl = conf.settings.OAUTH2_TOKEN_URL,
    authorizeUrl = conf.settings.OAUTH2_AUTHORIZE_URL
  ) {
    this._redirectUri = redirectUri;
    this._oauth2Client = new (require('client-oauth2'))({
      clientId,
      clientSecret,
      accessTokenUri: tokenUrl,
      authorizationUri: authorizeUrl,
      redirectUri: this._redirectUri
    });
  }

  authorizationCodeGrant(response) {
    response.navigate(this._oauth2Client.code.getUri());
  }

  tokenByCode(request) {
    const { code } = request.query;
    const redirectUri = /\?/.test(this._redirectUri)
      ? `${this._redirectUri}&code=${code}`
      : `${this._redirectUri}?code=${code}`;
    return this._oauth2Client.code.getToken(redirectUri);
  }

  static getBearerToken() {
    const token = this.loadToken();
    return `Bearer ${token}`;
  }

  static saveToken(token) {
    Cookies.set(conf.settings.AUTH_COOKIE_KEY, token.accessToken, {
      expires: token.expires,
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
  }

  static saveUserInfoToken(user) {
    Cookies.set(conf.settings.USER_INFO_COOKIE_KEY, JSON.stringify(user), {
      expires: 7,
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
  }

  static saveTempToken(token) {
    Cookies.set(conf.settings.AUTH_COOKIE_KEY, token.accessToken, {
      expires: 1,
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
  }

  static loadToken() {
    let token = Cookies.get(conf.settings.CUSTOMER_AUTH_COOKIE_KEY);
    if (!token) {
      token = Cookies.get(conf.settings.AUTH_COOKIE_KEY);
    }
    return token;
  }

  static removeUserInfoToken() {
    Cookies.remove(conf.settings.USER_INFO_COOKIE_KEY, {
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
  }

  static cleanToken() {
    Cookies.remove(conf.settings.AUTH_COOKIE_KEY, {
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
    Cookies.remove(conf.settings.CUSTOMER_AUTH_COOKIE_KEY, {
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
    Cookies.remove(conf.settings.INSPECTORS_AUTH_COOKIE_KEY, {
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
    Cookies.remove('pow-sessionid', {
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
    Cookies.remove('ae-website-sessionid', {
      domain: conf.settings.AUTH_COOKIE_DOMAIN
    });
    OAuth2.removeUserInfoToken();
  }
}
