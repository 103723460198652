import { conf } from 'outlinejs';
import { BaseCollection } from '../core/managers';
import { Project } from './models';

class BaseProjectCollection extends BaseCollection {}

export class CartProjectCollection extends BaseProjectCollection {
  get url() {
    return conf.settings.PROFESSIONAL_CART_PROJECTS_URL;
  }

  get model() {
    return Project;
  }

  async fetchCartProjects(shopCode, cartGuid) {
    const params = {
      pageSize: 100,
      shopCode,
      cartGuid,
      softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
    };
    return await this.fetch({ data: params });
  }

  async fetchAEGuides(shopCode, cartGuid) {
    const params = {
      shopCode,
      cartGuid,
      softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE,
      productType: conf.settings.PRODUCT_TYPE_AE_GUIDE,
      state: conf.settings.PROJECT_STATE_CART
    };
    return await this.fetch({ data: params });
  }
}

export class BucketProjectCollection extends BaseProjectCollection {
  get url() {
    return conf.settings.PROFESSIONAL_BUCKET_PROJECTS_URL;
  }

  get model() {
    return Project;
  }

  async fetchBucketProjects(shopCode, pageSize, currentPage = 1) {
    const collection = this;
    const options = {
      data: {
        shopCode,
        includeValidation: false,
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE,
        pageSize,
        currentPage
      },
      success(resp, status, xhr) {
        for (const item of collection.parse(resp, xhr)) {
          if (!collection.get(item.id)) {
            collection.add(item, { silent: true });
          }
        }
      },
      // eslint-disable-next-line
      error(resp) {}
    };
    return this.sync.call(this, 'read', this, options);
  }
}
