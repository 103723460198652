import { conf } from 'outlinejs';
import { BaseModel } from '../core/models';

export class BaseAddress extends BaseModel {
  get urlRoot() {
    return `${conf.settings.USER_BASE_ADDRESS_URL}`;
  }

  get addressUrl() {
    throw new Error('NotImplementedError: addressUrl');
  }

  get id() {
    return this.get('id');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get company() {
    return this.get('company');
  }

  get street() {
    return this.get('street');
  }

  get number() {
    return this.get('number');
  }

  get zipCode() {
    return this.get('zipCode');
  }

  get city() {
    return this.get('city');
  }

  get countryIsoName() {
    return this.get('countryIsoName');
  }

  fetch(options) {
    this.url = `${this.urlRoot}${this.shopCode}/${this.addressUrl}/`;
    return super.fetch(options);
  }
}

export class BillingAddress extends BaseAddress {
  get addressUrl() {
    return 'billing-address';
  }
}

export class ShippingAddress extends BaseAddress {
  get addressUrl() {
    return 'shipping-address';
  }
}
