import { BaseCollection } from '../core/managers';
import { conf } from 'outlinejs';
import { Voucher } from './models';

export class VouchersCollection extends BaseCollection {
  get name() {
    return 'ProfessionalVouchers';
  }

  get url() {
    return conf.settings.PROFESSIONAL_VOUCHERS_URL;
  }

  get model() {
    return Voucher;
  }

  async fetchVouchers(shopCode, country, language) {
    const params = {
      pageSize: 100,
      shopCode,
      country,
      languageCode: language,
      cartVisible: true,
      softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
    };
    return await this.fetch({ data: params });
  }
}
