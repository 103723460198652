import { conf } from 'outlinejs';
import { BaseCollection } from '../core/managers';
import {
  AlbumColorCorrectionType,
  EventBookColorCorrectionType,
  HomeDecorColorCorrectionType,
  MattedPrintsColorCorrectionType
} from './models';

export class AlbumColorCorrectionTypesCollection extends BaseCollection {
  get url() {
    return conf.settings.ALBUM_COLOR_CORRECTION_TYPE_URL;
  }

  get model() {
    return AlbumColorCorrectionType;
  }
}

export class EventBookColorCorrectionTypesCollection extends BaseCollection {
  get url() {
    return conf.settings.EVENT_BOOK_COLOR_CORRECTION_TYPE_URL;
  }

  get model() {
    return EventBookColorCorrectionType;
  }
}

export class MattedPrintsColorCorrectionTypesCollection extends BaseCollection {
  get url() {
    return conf.settings.MATTED_PRINTS_COLOR_CORRECTION_TYPE_URL;
  }

  get model() {
    return MattedPrintsColorCorrectionType;
  }
}

export class HomeDecorColorCorrectionTypesCollection extends BaseCollection {
  get url() {
    return conf.settings.HOME_DECOR_COLOR_CORRECTION_TYPE_URL;
  }

  get model() {
    return HomeDecorColorCorrectionType;
  }
}
