import { routers, routing } from 'outlinejs';
import { ResetController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('reset:main', ResetController)
    };
  }
}
