import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';
import { CustomError, VoucherError } from '../core/errors';

export class Voucher extends BaseModel {
  get urlRoot() {
    return conf.settings.PROFESSIONAL_VOUCHERS_URL;
  }

  get idAttribute() {
    return 'code';
  }

  get code() {
    return this.get('code');
  }

  get language() {
    return this.get('language');
  }

  set language(value) {
    this.set('language', value);
  }

  get campaignCode() {
    return this.get('campaignCode');
  }

  get description() {
    return this.get('description');
  }

  get isExpired() {
    return this.get('isExpired');
  }

  get assignedShopCode() {
    return this.get('assignedShopCode');
  }

  get usedDate() {
    return this.get('usedDate');
  }

  get expireDate() {
    return this.get('expireDate');
  }

  get endCampaignDate() {
    return this.get('endCampaignDate');
  }

  async checkValidity(voucherCode, shopCode) {
    try {
      await this.fetch();
    } catch (err) {
      if (err.code === 404) {
        throw new VoucherError('VoucherCodeNotExists');
      } else {
        throw new CustomError(err);
      }
    }

    if (this.isExpired) {
      throw new VoucherError('VoucherDateValidity');
    }

    if (this.assignedShopCode && this.assignedShopCode !== shopCode) {
      throw new VoucherError('ProfessionalUserListCondition');
    }

    if (this.usedDate) {
      throw new VoucherError('VoucherCodeBurned');
    }

    return this;
  }
}
