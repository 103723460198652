import { conf } from 'outlinejs';

export default class extends conf.BaseSettings {
  get MIDDLEWARE() {
    return [
      require('./auth/middleware').default,
      require('./core/middleware').default,
      require('./cart/middleware').default
    ];
  }

  get NODE_ENV() {
    return process.env.NODE_ENV;
  }

  get GTM_ID() {
    return process.env.OJS_GTM_ID;
  }

  get DIDOMI_API_KEY() {
    return process.env.OJS_DIDOMI_API_KEY;
  }

  get DIDOMI_CONSENT_ID() {
    return process.env.OJS_DIDOMI_CONSENT_ID;
  }

  get PROMO_URL() {
    return {
      it: 'https://www.albumepoca.com/it/ae_promozioni_iframe',
      es: 'https://www.albumepoca.com/es/ae_promozioni_iframe',
      en: 'https://www.albumepoca.com/en/ae_promozioni_iframe'
    };
  }

  get MEMBERSHIP_BASE_URL() {
    return 'http://boost.albumepoca.com/';
  }

  // LOGGER
  get SENTRY_DSN() {
    return process.env.OJS_SENTRY_DSN;
  }

  get AMPLITUDE_API_KEY() {
    return process.env.OJS_AMPLITUDE_API_KEY;
  }

  get AMPLITUDE_CONSENT_ID() {
    return 'c:amplitude';
  }

  get DEFAULT_LANGUAGE() {
    return 'it';
  }

  get LANGUAGES() {
    return ['en', 'it', 'es'];
  }

  get MAINTENANCE_MESSAGE() {
    return process.env.OJS_MAINTENANCE_MESSAGE === 'true';
  }

  get ALERT_ON_LOGIN() {
    return process.env.OJS_ALERT_ON_LOGIN === 'true';
  }

  get SHIPPING_URLS() {
    return {
      it: 'https://www.albumepoca.com/it/tempi_di_consegna_2019',
      en: 'https://www.albumepoca.com/en/delivery-time-christmas-2019',
      es: 'https://www.albumepoca.com/es/tiempos-de-entrega-navidad-2019'
    };
  }

  get PRODUCTS_LIST_URLS() {
    return {
      it: 'https://www.albumepoca.com/it/selezione_prodotti',
      en: 'https://www.albumepoca.com/en/product_selection',
      es: 'https://www.albumepoca.com/es/elige_producto'
    };
  }

  get PRICES_URLS() {
    return {
      it: 'https://my.albumepoca.com/#/main/albumepoca-lists',
      en: 'https://my.albumepoca.com/#/main/albumepoca-lists',
      es: 'https://my.albumepoca.com/#/main/albumepoca-lists'
    };
  }

  get ENV_PRODUCTION() {
    return this.NODE_ENV === 'production';
  }

  get X_API_KEY() {
    return process.env.OJS_X_API_KEY;
  }

  get REFERRAL_AVAILABLE_LANGUAGES() {
    return ['en', 'es', 'it'];
  }

  get HOMEPAGE_BASE_URL() {
    return process.env.OJS_HOMEPAGE_BASE_URL;
  }

  get AE_WEBSITE_BASE_URL() {
    return process.env.OJS_AE_WEBSITE_BASE_URL;
  }

  get AE_CONFIGURATOR_BASE_URL() {
    return process.env.OJS_CONFIGURATOR_BASE_URL;
  }

  get REGISTER_PATH() {
    return 'pif-auth/registration/';
  }

  get NO_PROFESSIONAL_PATH() {
    return 'no-professional/';
  }

  // INFINITE SCROLL PAGE SIZE
  get PAGE_SIZE() {
    return 10;
  }

  // OAUTH2 and USER
  get USER_URL() {
    return `${this.API_BASE_URL}accounts/professional-users/`;
  }

  get USER_BASE_ADDRESS_URL() {
    return `${this.API_BASE_URL}accounts/professional-users/`;
  }

  get OAUTH2_LOGOUT_URL() {
    return process.env.OJS_OAUTH2_LOGOUT_URL;
  }

  get OAUTH2_CLIENT_ID() {
    return process.env.OJS_OAUTH2_CLIENT_ID;
  }

  get OAUTH2_CLIENT_SECRET() {
    return process.env.OJS_OAUTH2_CLIENT_SECRET;
  }

  get OAUTH2_TOKEN_URL() {
    return process.env.OJS_OAUTH2_TOKEN_URL;
  }

  get OAUTH2_AUTHORIZE_URL() {
    return process.env.OJS_OAUTH2_AUTHORIZE_URL;
  }

  get AUTH_COOKIE_DOMAIN() {
    return process.env.OJS_AUTH_COOKIE_DOMAIN;
  }

  get AUTH_COOKIE_KEY() {
    return process.env.OJS_AUTH_COOKIE_KEY;
  }

  get USER_INFO_COOKIE_KEY() {
    return process.env.OJS_USER_INFO_COOKIE_KEY;
  }

  get CUSTOMER_AUTH_COOKIE_KEY() {
    return `customer_${this.AUTH_COOKIE_KEY}`;
  }

  get CART_DOMAIN() {
    return process.env.OJS_CART_DOMAIN;
  }

  get API_BASE_URL() {
    return process.env.OJS_API_BASE_URL;
  }

  get API_VERSION() {
    return process.env.OJS_API_VERSION || '0.0.1';
  }

  get MYPHOTOSI_BASE_URL() {
    return process.env.OJS_MYPHOTOSI_BASE_URL;
  }

  get MYPHOTOSI_ORDERS_URL() {
    return `${process.env.OJS_MYPHOTOSI_BASE_URL}#/main/orders/professional`;
  }

  // API
  get PROFESSIONAL_PROJECTS_URL() {
    return `${this.API_BASE_URL}projects/professional/projects/`;
  }

  get PROFESSIONAL_BUCKET_PROJECTS_URL() {
    return `${this.API_BASE_URL}projects/professional/projects/`;
  }

  get PROFESSIONAL_CART_PROJECTS_URL() {
    return `${this.API_BASE_URL}carts/professional/rows/`;
  }

  get PROFESSIONAL_PRE_ORDERS_URL() {
    return `${this.API_BASE_URL}pre-orders/professional/pre-orders/`;
  }

  get PROFESSIONAL_ORDER_HEADER_URL() {
    return `${this.API_BASE_URL}orders/professional/headers/`;
  }

  get PROFESSIONAL_CART_HEADER_URL() {
    return `${this.API_BASE_URL}carts/professional/headers/`;
  }

  get PROFESSIONAL_CART_PAYMENT_METHOD() {
    return `${this.API_BASE_URL}carts/professional/headers/current/payment-methods/`;
  }

  get PROFESSIONAL_ORDER_ROWS_URL() {
    return `${this.API_BASE_URL}orders/professional/rows`;
  }

  get PROFESSIONAL_VOUCHERS_URL() {
    return `${this.API_BASE_URL}promotions/professional/vouchers`;
  }

  get ORDER_CONFIRMED_STATUS() {
    return ['confirmed', 'paymentcompleted'];
  }

  get CART_ENABLE_STATUS() {
    return ['new'];
  }

  // ADMIN_AREA_URLS
  get ADMIN_AREA_URL() {
    return this.MYPHOTOSI_BASE_URL;
  }

  get PROJECT_STATE_CART() {
    return 'Cart';
  }

  get PROJECT_STATE_BUCKET() {
    return 'Bucket';
  }

  get PRODUCT_TYPE_ALBUM() {
    return 'Album';
  }

  get PRODUCT_TYPE_EVENT_BOOK() {
    return 'EventBook';
  }

  get PRODUCT_TYPE_AE_GUIDE() {
    return 'AEGuide';
  }

  get PRODUCT_TYPE_MATTED_PRINTS() {
    return 'MattedPrints';
  }

  get PRODUCT_TYPE_MATTED_PRINTS_STAND() {
    return 'MattedPrintsStand';
  }

  get PRODUCT_TYPE_HOME_DECOR() {
    return 'HomeDecor';
  }

  get PRODUCT_TYPE_USB_DRIVE() {
    return 'UsbDrive';
  }

  get PRODUCT_TYPE_USB_DRIVE_PACKAGING() {
    return 'UsbDrivePackaging';
  }

  get PROJECT_FULL_SERVICE_DESIGN() {
    return 'FullServiceDesign';
  }

  get SHOW_MEMBERSHIP_URL() {
    return false;
  }

  get AEGUIDE_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/guide/configurations/`;
  }

  get ENABLED_SHOPCODES() {
    return [
      '00010031260', // test.it.albumepoca@gmail.com
      '00010031314', // test.es.albumepoca@gmail.com
      '00010031315', // test.ca.albumepoca@gmail.com
      '00010031320', // test.uk.albumepoca@gmail.com
      '00010031318', // test.ie.albumepoca@gmail.com
      '00010031319', // test.si.albumepoca@gmail.com
      '00010032820', // test.de.albumepoca@gmail.com
      '00010031313', // test.e2.albumepoca@gmail.com
      '00010031317' // test.in.albumepoca@gmail.com
      // '00010031171', //test.us.albumepoca@gmail.com
      // '00010031312' //test.ww.albumepoca@gmail.com
    ];
  }

  get ALBUM_COLOR_CORRECTION_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/color-correction-codes`;
  }

  get EVENT_BOOK_COLOR_CORRECTION_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/color-correction-codes`;
  }

  get MATTED_PRINTS_COLOR_CORRECTION_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/lookups/color-correction-codes`;
  }

  get HOME_DECOR_COLOR_CORRECTION_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/color-correction-codes`;
  }

  get PROFESSIONAL_PROJECTS_RESET_URL() {
    return `${this.API_BASE_URL}projects/professional/projects/reset`;
  }

  get PRICING_EXCHANGE_RATES_URL() {
    return `${this.API_BASE_URL}pricings/exchange-rates/`;
  }

  get PROJECTS_QUANTITY_URL() {
    return `${this.API_BASE_URL}projects/professional/projects/`;
  }

  get RESET_PROJECTS_ENABLED_SHOP_CODES() {
    return [
      '00010057604',
      '00010057605',
      '00010057606',
      '00010057607',
      '00010057603',
      '00010055743'
    ];
  }

  get EDITOR_BASE_APPLICATION_URL() {
    return process.env.OJS_EDITOR_BASE_APPLICATION_URL;
  }

  get PROFESSIONAL_SOFTWARE_CODE() {
    return 'PROFESSIONAL';
  }

  get PAYMENT_OK() {
    return 'ok';
  }

  get PAYMENT_KO() {
    return 'ko';
  }

  get COUPON_FAQ_URLS() {
    return {
      it: 'https://albumepoca.zendesk.com/hc/it/sections/4403349006482-Promozioni',
      es: 'https://albumepoca.zendesk.com/hc/es/sections/4403349006482-Promociones',
      en: 'https://albumepoca.zendesk.com/hc/en-us/sections/4403349006482-Promotions'
    };
  }

  get RESERVED_AREA_VOUCHER_URL() {
    return 'https://my.albumepoca.com/#/main/vouchers';
  }
}
