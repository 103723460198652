import { BaseModel } from '../core/models';

export class AlbumColorCorrectionType extends BaseModel {
  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookColorCorrectionType extends BaseModel {
  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class MattedPrintsColorCorrectionType extends BaseModel {
  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class HomeDecorColorCorrectionType extends BaseModel {
  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}
