import { routers, routing } from 'outlinejs';

import { LoginController, LogoutController, UnauthorizedController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('login', LoginController),
      logout: routing.url('logout', LogoutController),
      unauthorized: routing.url('unauthorized', UnauthorizedController)
    };
  }
}
