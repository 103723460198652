import React from 'react';

import { views } from 'outlinejs';
import { EpocaBaseContentView } from '../core/views';

export class LoginView extends views.BaseView {
  render() {
    return <div className="loading-widget fill" />;
  }
}

export class LogoutView extends views.BaseView {
  render() {
    return <div className="loading-widget fill" />;
  }
}

export class UnauthorizedView extends EpocaBaseContentView {
  renderContent() {
    return (
      <div className="thankyou_page">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="cart-main-title">
              {this.i18n.gettext('Non sei autorizzato a visualizzare questa pagina')}
            </h1>
          </div>
        </div>
      </div>
    );
  }
}
