import { controllers, conf, routing } from 'outlinejs';

import { runtime } from '@outlinejs/contexts';

import { EpocaBaseController } from '../core/controllers';

import Logger from '../core/logger';

import OAuth2 from './oauth';
import { LoginView, LogoutView, UnauthorizedView } from './views';
import { performLogout } from './utils';

export class LoginController extends controllers.BaseController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return LoginView;
  }

  init() {
    if (runtime.isClient) {
      if (this.request.query['next-url'] && this.request.user) {
        this.response.navigate(this.request.query['next-url']);
      } else {
        const oauthClient = new OAuth2(this.request.absoluteUrl);
        oauthClient.authorizationCodeGrant(this.response);
      }
    } else {
      this.render();
    }
  }
}

export class LogoutController extends controllers.BaseController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return LogoutView;
  }

  init() {
    if (runtime.isClient) {
      performLogout();

      let nextUrl = this.request.query['next-url'];

      if (!nextUrl) {
        nextUrl = this.request.absoluteUrl;
      }

      // patch url if we are in th unauthorized page
      const unauthorizedUrl = routing.Utils.reverse('unauthorized');
      if (decodeURI(nextUrl).indexOf(unauthorizedUrl) != -1) {
        //eslint-disable-line
        try {
          nextUrl = `${window.location.protocol}//${window.location.host}${routing.Utils.reverse(
            'cart:main'
          )}`;
        } catch (err) {
          Logger.error('Unable to redirect to cart from unauthorized page', {
            error: err
          });
        }
      }

      const logoutUrl = `${conf.settings.OAUTH2_LOGOUT_URL}?next=${nextUrl}`;
      Logger.log(logoutUrl);
      this.response.navigate(logoutUrl);
    } else {
      this.render();
    }
  }
}

export class UnauthorizedController extends EpocaBaseController {
  static get loginRequired() {
    return false;
  }

  get context() {
    return Object.assign(super.context, {
      adminUrl: conf.settings.ADMIN_AREA_URL,
      topMenuBarVisible: this.topMenuBarVisible,
      contentIsLoading: this.contentIsLoading,
      initViewRendering: this.initViewRendering
    });
  }

  get view() {
    return UnauthorizedView;
  }

  async init() {
    await this.initContentProps();
    this.topMenuBarVisible = false;
    this.stopLoadingPage();
  }
}
