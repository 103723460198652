import { conf } from 'outlinejs';
import { BaseModel, mergeBackboneOptions } from '../core/models';
import { ProfessionalPreOrdersCollection } from '../pre-orders/managers';

export class Project extends BaseModel {
  constructor(...args) {
    super(...args);
    this._preorderCollection = new ProfessionalPreOrdersCollection();

    this._isPreorderCollectionLoading = undefined;
    this._preorderCollectionLoaded = undefined;

    this._isValidationLoading = undefined;
    this._validationLoaded = undefined;
  }

  fetch(options = { data: { includeValidation: true } }) {
    const params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }

  async fetchValidation(options = {}) {
    const previousModel = this.toJSON();

    const params = {
      data: {
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE,
        includeValidation: true
      }
    };
    options = mergeBackboneOptions(options, params);
    this.url = `${this.urlRoot}${this.id}/validation`;

    this.isValidationLoading = true;
    await super.fetch(options);

    this.url = `${this.urlRoot}${this.id}`;
    this.isValidationLoading = false;
    const validationModel = this.toJSON();

    this.set(previousModel);
    for (const [key, value] of Object.entries(this.attributes)) {
      if (value === null) {
        this[key] = validationModel[key];
      }
    }

    this._validationLoaded = true;
    return this;
  }

  async fetchPreorderCollection(includePreOrderDescription = false) {
    this.isPreorderCollectionLoading = true;
    await this._preorderCollection.fetchPreOrders(this.preOrders, includePreOrderDescription);

    const colorCorrectionCodes = await Promise.all(
      this._preorderCollection.map((preOrder) => preOrder.getColorCorrectionItems())
    );

    this._colorCorrectionTypes = [];
    for (let i = 0; i < colorCorrectionCodes.length; i++) {
      if (colorCorrectionCodes[i]) {
        this._colorCorrectionTypes = this._colorCorrectionTypes.concat(colorCorrectionCodes[i]);
      }
    }
    this.isPreorderCollectionLoading = false;
    this._preorderCollectionLoaded = true;
  }

  getAbsoluteUrl() {
    return `${this.urlRoot + this.id}/`;
  }

  get urlRoot() {
    return conf.settings.PROFESSIONAL_BUCKET_PROJECTS_URL;
  }

  get id() {
    return this.get('id');
  }

  get preOrders() {
    return this.get('preOrders');
  }

  set preOrders(value) {
    this.set('preOrders', value);
  }

  get preorders() {
    if (!this.get('preOrders')) {
      return [];
    }
    return this.get('preOrders').map((preOrderData) => preOrderData.preorderId); //eslint-disable-line
  }

  set preorders(value) {
    this.set('preorders', value);
  }

  get state() {
    return this.get('state');
  }

  set state(value) {
    this.set('state', value);
  }

  get name() {
    return this.get('name');
  }

  set name(value) {
    this.set('name', value);
  }

  get productCategory() {
    return this.get('productCategory');
  }

  set productCategory(value) {
    this.set('productCategory', value);
  }

  get formattedTotalPrice() {
    return this.get('formattedTotalPrice');
  }

  set formattedTotalPrice(value) {
    this.set('formattedTotalPrice', value);
  }

  get formattedTotalDiscountPrice() {
    return this.get('formattedTotalDiscountPrice');
  }

  set formattedTotalDiscountPrice(value) {
    this.set('formattedTotalDiscountPrice', value);
  }

  get canBeOrdered() {
    return this.get('canBeOrdered');
  }

  set canBeOrdered(value) {
    this.set('canBeOrdered', value);
  }

  get canBeModified() {
    return this.get('canBeModified');
  }

  set canBeModified(value) {
    this.set('canBeModified', value);
  }

  get canBeDuplicated() {
    return this.get('canBeDuplicated');
  }

  set canBeDuplicated(value) {
    this.set('canBeDuplicated', value);
  }

  get canBeShared() {
    return this.get('canBeShared');
  }

  set canBeShared(value) {
    this.set('canBeShared', value);
  }

  get creationStatus() {
    return this.get('creationStatus');
  }

  set creationStatus(value) {
    this.set('creationStatus', value);
  }

  get isOutOfProduction() {
    return this.get('isOutOfProduction');
  }

  set isOutOfProduction(value) {
    this.set('isOutOfProduction', value);
  }

  get preorderCollection() {
    return this._preorderCollection;
  }

  get colorCorrectionTypes() {
    return this._colorCorrectionTypes;
  }

  set colorCorrectionTypes(value) {
    this._colorCorrectionTypes = value;
    // return value;
  }

  isDuplicationInProgress() {
    return this.creationStatus.toUpperCase() === 'INPROGRESS';
  }

  isCreationStatusValid() {
    return this.creationStatus.toUpperCase() === 'COMPLETED';
  }

  isCreationStatusError() {
    return this.creationStatus.toUpperCase() === 'ERROR';
  }

  get isValidationLoading() {
    return this._isValidationLoading;
  }

  set isValidationLoading(value) {
    this._isValidationLoading = value;
    // return value;
  }

  get isPreorderCollectionLoading() {
    return this._isPreorderCollectionLoading;
  }

  set isPreorderCollectionLoading(value) {
    this._isPreorderCollectionLoading = value;
    // return value;
  }

  get loaded() {
    return this._validationLoaded && this._preorderCollectionLoaded;
  }

  get preorderCollectionLoaded() {
    return this._preorderCollectionLoaded;
  }

  set preorderCollectionLoaded(value) {
    this._preorderCollectionLoaded = value;
    // return value;
  }
}
