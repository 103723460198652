import React from 'react';
import { EpocaBaseContentView } from '../core/views';

/**
 * Main ThankYou content View.
 *
 * NOTE: render method is defined in EpocaBaseContentView
 * */
export class ResetView extends EpocaBaseContentView {
  constructor(props) {
    super(props);
    this.backToSite = this.backToSite.bind(this);
    this.deleteAll = this.deleteAll.bind(this);
  }

  backToSite() {
    this.props.delegate.backToSite();
  }

  deleteAll() {
    this.props.delegate.deleteAll();
  }

  renderContent() {
    const { success, error } = this.props;

    return (
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-center">
            Attenzione! <br />
            Cliccando sul pulsante verranno eliminati tutti i progetti dal carrello. <br />
            Sei sicuro di voler proseguire?
          </h2>

          <div className="row">
            <div className="col-xs-6">
              <button className="btn btn-default btn-block" onClick={this.backToSite}>
                Torna al carrello
              </button>
            </div>
            <div className="col-xs-6">
              <button className="btn btn-primary btn-block" onClick={this.deleteAll}>
                Cancella tutto
              </button>
            </div>
          </div>

          <br />
          {success && <div className="alert alert-success">Operazione eseguita con successo</div>}
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </div>
    );
  }
}
